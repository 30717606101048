import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';

import styles from './DialogContent.module.css';

interface Props extends Omit<MuiDialogContentProps, 'classes'> {}

const DialogContent = (props: Props) => {
  return <MuiDialogContent {...props} classes={styles} />;
};

export default DialogContent;
