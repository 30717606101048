import { getSession, signIn, useSession } from 'next-auth/react';
import { parse } from 'qs';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-use';

import Button from 'client/elements/Button';
import Dialog from 'client/elements/Dialog';
import DialogActions from 'client/elements/DialogActions';
import DialogContent from 'client/elements/DialogContent';
import IconButton from 'client/elements/IconButton';
import { VisibilityIcon, VisibilityOffIcon } from 'client/elements/Icons';
import InputAdornment from 'client/elements/InputAdornment';
import TextField from 'client/elements/TextField';
import Typography from 'client/elements/Typography';

import { ErrorCoded } from 'client/utils/error';
import { useTranslation } from 'client/utils/i18n';
import { makeCss } from 'client/utils/styles';

import useCart from './useCart';
import useErrorNotification from './useErrorNotification';
import useNotifications from './useNotifications';

interface SignInContextValue {
  openSignInDialog: () => void;
}
const SignInContext = React.createContext<SignInContextValue>({ openSignInDialog: () => null });

const useCss = makeCss({
  forgottenPasswordLink: {
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const SignInProvider = ({ children }: { children: ReactNode }) => {
  const css = useCss();
  const { t } = useTranslation();
  const session = useSession();
  const { hash } = useLocation();
  const { notify } = useNotifications();
  const { notifyError } = useErrorNotification();
  const { onUserChange } = useCart();

  const [open, setOpen] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const [forgottenPassword, setForgottenPassword] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (!hash) return;

    const [path, search] = hash.split('#')[1].split('?');
    if (path !== 'sign-in') return;
    setOpen(true);

    const { login_hint } = parse(search) as { login_hint?: string };
    if (login_hint) setEmail(login_hint);
  }, [hash]);

  const handleSignInSubmit = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (submitting) return;

      setSubmitting(true);
      setShowPassword(false);

      try {
        const signInResult = await signIn('credentials', {
          redirect: false,
          email: event.target.elements.email.value,
          password: event.target.elements.password.value,
        });
        if (!signInResult?.ok) {
          setSubmitting(false);
          return notifyError(new ErrorCoded('login_password_does_not_match'));
        }

        const newSession = await getSession();
        if (!newSession) {
          setSubmitting(false);
          return notifyError(new ErrorCoded('retrieve_session_failed'));
        }

        await onUserChange({
          action: 'sign-in',
          previousUserId: session.data?.user.idAsNumber,
          newUserId: newSession.user.idAsNumber,
        });
      } catch (error) {
        console.error('handleSignInSubmit signIn error', error);
        setSubmitting(false);

        return notifyError(error);
      }

      setSubmitting(false);
      setOpen(false);
    },
    [notifyError, submitting, onUserChange, session.data?.user.idAsNumber],
  );

  const handleForgottenPasswordSubmit = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (submitting) return;

      setSubmitting(true);
      setShowPassword(false);

      let passwordResetResponse: Response;
      try {
        passwordResetResponse = await fetch('/api/passwords/forgotten', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: event.target.elements.email.value }),
        });
      } catch (error) {
        setSubmitting(false);

        return notifyError(error);
      }

      setSubmitting(false);

      if (!passwordResetResponse.ok) {
        return notifyError(new ErrorCoded('request_500'));
      }

      notify(t('main:password_reset_email_sent'), 'success');
      setForgottenPassword(false);
    },
    [t, notify, notifyError, submitting],
  );

  const signInContextValue: SignInContextValue = React.useMemo(
    () => ({ openSignInDialog: () => setOpen(true) }),
    [],
  );
  return (
    <SignInContext.Provider value={signInContextValue}>
      {children}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        header={forgottenPassword ? t('main:password_forgotten') : t('main:sign_in')}
        fullWidth={true}
        disableFullScreen={true}
      >
        {forgottenPassword ? (
          <form onSubmit={handleForgottenPasswordSubmit}>
            <DialogContent key="forgotten-password">
              <TextField
                type="email"
                name="email"
                label={t('main:email_address')}
                variant="outlined"
                disabled={submitting}
                required={true}
                autoFocus={true}
                fullWidth={true}
                autoComplete="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => setForgottenPassword(false)}
              >
                {t('main:cancel')}
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disableElevation={true}
                loading={submitting}
              >
                {t('main:send_me_email')}
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form key="sign-in" onSubmit={handleSignInSubmit}>
            <DialogContent>
              <TextField
                type="email"
                name="email"
                label={t('main:email_address')}
                variant="outlined"
                disabled={submitting}
                required={true}
                autoFocus={true}
                fullWidth={true}
                autoComplete="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />

              <TextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                label={t('main:password')}
                margin="normal"
                variant="outlined"
                required={true}
                disabled={submitting}
                fullWidth={true}
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword ? t('main:hide_password') : t('main:show_password')
                        }
                        onClick={() => setShowPassword(!showPassword)}
                        size="large"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={event => setPassword(event.target.value)}
              />

              <Typography
                css={css.forgottenPasswordLink}
                onClick={() => setForgottenPassword(true)}
              >
                {t('main:password_forgotten_text')}
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disableElevation={true}
                loading={submitting}
              >
                {t('main:sign_in')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </SignInContext.Provider>
  );
};

export default function useSignIn() {
  return React.useContext(SignInContext);
}
