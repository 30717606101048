import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { Configuration, TelemetryClient } from 'applicationinsights';

export { SeverityLevel };

const isProduction = process.env.NODE_ENV === 'production';
const APP_INSIGHTS_KEY = 'bf813a2b-7ac4-4114-8a0d-1d4f8abd7afb';

let appInsightsBrowser: ApplicationInsights;
let appInsightsServer: TelemetryClient;
if (typeof window !== 'undefined') {
  appInsightsBrowser = new ApplicationInsights({
    config: {
      instrumentationKey: APP_INSIGHTS_KEY,
      disableTelemetry: !isProduction,
      disableExceptionTracking: !isProduction,
    },
  });
  appInsightsBrowser.loadAppInsights();

  appInsightsBrowser.addTelemetryInitializer(envelope => {
    if (!envelope.data) envelope.data = {};
    envelope.data.userAgent = window.navigator.userAgent;

    if (!envelope.tags) envelope.tags = [];
    envelope.tags['ai.cloud.role'] = 'shop-web';
    envelope.tags['ai.cloud.roleInstance'] = 'shop-web';
  });
} else {
  // @ts-ignore:no-var-requires
  // tslint:disable-next-line: no-var-requires
  const appInsights = require('applicationinsights');
  const configuration: typeof Configuration = appInsights.setup(APP_INSIGHTS_KEY);

  configuration
    .setAutoCollectConsole(false)
    .setAutoCollectDependencies(true)
    .setAutoCollectExceptions(true)
    .setAutoCollectPerformance(false)
    .setAutoCollectRequests(true)
    .setAutoDependencyCorrelation(true)
    .setUseDiskRetryCaching(false)
    .start();
  appInsightsServer = appInsights.defaultClient;
  appInsightsServer.config.samplingPercentage = 100;
  appInsightsServer.context.tags[appInsightsServer.context.keys.cloudRole] = 'shop-server';
  appInsightsServer.context.tags[appInsightsServer.context.keys.cloudRoleInstance] = 'shop-server';
}

const applicationInsights = {
  trackTrace(
    message: string,
    customProperties: {
      [key: string]:
        | { [key: string]: string[] | string | undefined }
        | string[]
        | string
        | undefined;
    },
    severityLevel: SeverityLevel,
  ): void {
    if (isProduction) {
      if (appInsightsBrowser) {
        appInsightsBrowser.trackTrace({ message, severityLevel }, customProperties);
      } else if (appInsightsServer) {
        appInsightsServer.trackTrace({
          message,
          properties: customProperties,
          severity: severityLevel,
        });
      }
    }
  },
  trackException(
    exception: Omit<IExceptionTelemetry, 'exception'> & {
      exception: Error;
    },
    customProperties: { [key: string]: any } = {},
    severityLevel: SeverityLevel,
  ): void {
    if (isProduction) {
      if (appInsightsBrowser) {
        appInsightsBrowser.trackException({
          ...exception,
          properties: customProperties,
          severityLevel,
        });
      } else if (appInsightsServer) {
        appInsightsServer.trackException({
          ...exception,
          properties: customProperties,
          severity: severityLevel,
        });
      }
    }
  },
};

export default applicationInsights;
