import createCache from '@emotion/cache';
import { CSSObject, SerializedStyles, css } from '@emotion/react';
import { Theme, createTheme, useTheme } from '@mui/material/styles';
import React from 'react';

const font1 = "'Muli', sans-serif";
const font2 = "'Muli', sans-serif";

interface UserTheme {
  primaryColor?: string;
  secondaryColor?: string;
}

export function getTheme(userTheme: UserTheme) {
  return createTheme({
    palette: {
      primary: {
        main: userTheme.primaryColor || '#588a7f',
      },
      secondary: {
        main: userTheme.secondaryColor || '#deb772',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.75)',
      },
      warning: {
        main: '#ffa000',
      },
      success: {
        main: '#43a047',
      },
      info: {
        main: '#1976d2',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      h1: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '2rem',
      },
      h2: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '1.7rem',
      },
      h3: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '1.5rem',
      },
      h4: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '1.4rem',
      },
      h5: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '1.3rem',
      },
      h6: {
        fontWeight: 'bold',
        fontFamily: font1,
        fontSize: '1.2rem',
      },
      subtitle1: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
        fontFamily: font1,
      },
      subtitle2: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
        fontFamily: font1,
      },
      fontFamily: font2,
      fontSize: 16,
    },

    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: font2,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          entered: {
            height: 'auto',
            overflow: 'visible',
          },
        },
      },
    },
  });
}

// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
  return createCache({ key: 'css', prepend: true });
}

function serializeCss<T extends Record<string, CSSObject>>(styles: T) {
  return Object.entries(styles).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: css(value) }),
    {} as Record<keyof T, SerializedStyles>,
  );
}

export function makeCss<T extends Record<string, CSSObject>>(styles: T | ((theme: Theme) => T)) {
  if (typeof styles !== 'function') {
    const serializedStyles = serializeCss(styles);
    return () => serializedStyles;
  }

  return () => {
    const theme = useTheme();
    return React.useMemo(() => serializeCss(styles(theme)), [theme]);
  };
}
