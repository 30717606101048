import Slug from 'slug';

import {
  BorderInputValue,
  InputValue,
  OptionInputValue,
  OptionValues,
  OptionsValues,
  ProductBorder,
  ProductCondition,
  ProductId,
  ProductInput,
  ProductOption,
  RepeatOptionValues,
  StandardOptionValues,
} from 'types/products';
import { Locale } from 'types/translations';

import { calculateTax } from './taxes';
import { assertNever } from './type';

export function getProductSlug(productName: string) {
  return Slug(productName, { lower: true });
}

export function getProductBaseUrl(id: number) {
  return `/products/${id}`;
}

const defaultLocale: Locale = 'fr';
export function getProductUrl(
  data: { id: number; name: { [key: string]: string | undefined } },
  locale?: string,
) {
  const name = data.name[locale || defaultLocale] || data.name[defaultLocale] || '';
  return `${getProductBaseUrl(data.id)}/${getProductSlug(name)}`;
}

export function isConditionValid(conditions: ProductCondition[], optionValues: OptionValues) {
  if (!conditions || conditions.length === 0) {
    return true;
  }

  return conditions.some(({ input, checker, value }) => {
    let verified = false;

    if (Array.isArray(optionValues)) {
      return verified;
    }

    const currentValue = optionValues[input];
    if (typeof currentValue === 'object') {
      return verified;
    }

    switch (checker) {
      case '=':
        verified = value === currentValue;
        break;
      case '!=':
        verified = value !== currentValue;
        break;
      default:
        verified = false;
    }

    return verified;
  });
}

export function getOptionsValues(
  productOptions: ProductOption[],
  productBorders: ProductBorder[],
  currentOptionsValues?: OptionsValues,
  newInputValuePath?: string,
  newInputValue?: InputValue,
): OptionsValues {
  const newOptionsValues = productOptions.reduce((acc, productOption) => {
    let optionValues: OptionValues;

    if (productOption.repeat && productOption.repeat.type === 'option') {
      const currentOptionValues: RepeatOptionValues =
        (currentOptionsValues?.[productOption.id] as RepeatOptionValues) ?? [];

      optionValues = currentOptionValues.map((currentOptionValues, index) => {
        return getOptionValues(
          productOption,
          productBorders,
          `${productOption.id}[${index}]`,
          currentOptionValues,
          newInputValuePath,
          newInputValue,
        );
      });
    } else {
      const currentOptionValues =
        currentOptionsValues && (currentOptionsValues[productOption.id] as StandardOptionValues);

      optionValues = getOptionValues(
        productOption,
        productBorders,
        `${productOption.id}`,
        currentOptionValues,
        newInputValuePath,
        newInputValue,
      );
    }

    return { ...acc, [productOption.id]: optionValues };
  }, {} as OptionsValues);

  return newOptionsValues;
}

export function getOptionValues(
  productOption: ProductOption,
  productBorders: ProductBorder[],
  basePath: string,
  currentOptionValues?: StandardOptionValues,
  newInputValuePath?: string,
  newInputValue?: InputValue,
): StandardOptionValues {
  let newOptionValues: StandardOptionValues;

  newOptionValues = productOption.inputs.reduce((acc, input) => {
    if (input.conditions && !isConditionValid(input.conditions, acc)) {
      return acc;
    }

    const inputPath = `${basePath}[${input.name}]`;
    const currentInputValue = currentOptionValues && currentOptionValues[input.name];

    let value: OptionInputValue;

    if (input.repeat && input.repeat === 'borders') {
      value = getRepeatBordersInputValue(
        productBorders,
        input,
        inputPath,
        currentInputValue,
        newInputValuePath,
        newInputValue,
      );
    } else if (typeof newInputValue !== 'undefined' && inputPath === newInputValuePath) {
      value = newInputValue;
    } else {
      value = getInputValue(input, currentInputValue, acc);
    }

    return {
      ...acc,
      [input.name]: value,
    };
  }, {} as StandardOptionValues);

  return newOptionValues;
}

export function getRepeatBordersInputValue(
  productBorders: ProductBorder[],
  input: ProductInput,
  inputPath: string,
  currentInputValue?: OptionInputValue,
  newInputValuePath?: string,
  newInputValue?: InputValue,
) {
  return productBorders.reduce(
    (acc, border, i) => {
      const borderNumber = i + 1;
      const borderName = `${input.name}-border-${borderNumber}`;
      const borderPath = `${inputPath}[${borderName}]`;

      let borderInputValue: boolean;
      if (border.mustBeFolded) {
        borderInputValue = true;
      } else if (borderPath === newInputValuePath) {
        borderInputValue = Boolean(newInputValue);
      } else {
        borderInputValue = Boolean(
          getInputValue(
            input,
            currentInputValue && (currentInputValue as BorderInputValue)[borderName],
            acc,
          ),
        );
      }

      return {
        ...acc,
        [borderName]: borderInputValue,
      };
    },
    {} as Record<string, boolean>,
  );
}

export function getInputValue(
  input: ProductInput,
  currentInputValue: OptionInputValue | undefined,
  optionValues: OptionValues,
): OptionInputValue {
  if (input.type === 'select' || input.type === 'radio' || input.type === 'gallery') {
    const defaultValue = input.value;
    const availableOptions = input.options.filter(
      option => !option.conditions || isConditionValid(option.conditions, optionValues),
    );

    if (currentInputValue && availableOptions.some(option => option.value === currentInputValue)) {
      return currentInputValue;
    } else if (availableOptions.some(option => option.value === defaultValue)) {
      return defaultValue;
    } else {
      return '';
    }
  } else if (input.type === 'checkbox' || input.type === 'switch') {
    return typeof currentInputValue === 'boolean' ? currentInputValue : input.checked;
  } else if (input.type === 'number' || input.type === 'hidden') {
    return typeof currentInputValue === 'number' ? currentInputValue : input.value;
  } else if (input.type === 'table') {
    return typeof currentInputValue === 'object'
      ? currentInputValue
      : { [input.x.name]: input.x.value, [input.y.name]: input.y.value };
  } else {
    assertNever(input);
  }
}

export function getProductPrice(
  productPrice: number,
  optionsPrice: number,
  {
    productsDiscount,
    optionsDiscount,
    margin,
    deliveryCountryId,
    taxNumber,
  }: {
    productsDiscount?: number | null;
    optionsDiscount?: number | null;
    margin?: number | null;
    deliveryCountryId?: number;
    taxNumber?: string;
  } = {},
) {
  if (!margin) margin = 1;

  if (productsDiscount) {
    productPrice += Math.round(productPrice * (productsDiscount / 100) * 100) / 100;
  }

  if (optionsDiscount) {
    optionsPrice += Math.round(optionsPrice * (optionsDiscount / 100) * 100) / 100;
  }

  // resaleWithTax / buyingWithoutTax = margin - explanations Ticket N°534
  const subTotal =
    margin > 1
      ? Math.round((((productPrice + optionsPrice) * margin) / 1.2) * 100) / 100
      : productPrice + optionsPrice;
  const { taxRate, taxAmount } = calculateTax(subTotal, { deliveryCountryId, taxNumber });
  const total =
    margin > 1
      ? Math.round((productPrice + optionsPrice) * margin * 100) / 100
      : subTotal + taxAmount;

  return {
    product: productPrice,
    options: optionsPrice,
    subTotal,
    taxRate,
    tax: taxAmount,
    total,
  };
}

export function getOptionsValuesAsText(
  productOptions: ProductOption[],
  optionsValues: OptionsValues,
  locale: Locale,
) {
  return productOptions.map(option => {
    const optionValues = optionsValues[option.id];

    return {
      name: option.name[locale] || 'Unknown',
      value:
        optionValues && Array.isArray(optionValues)
          ? optionValues.map((optionValues, i) => {
              return {
                name: `${option.name[locale]} ${i + 1}`,
                value: getOptionValueAsText(option, optionValues, locale),
              };
            })
          : getOptionValueAsText(option, optionValues, locale),
    };
  });
}

function getOptionValueAsText(
  option: ProductOption,
  optionValues: StandardOptionValues,
  locale: Locale,
) {
  return (
    option.inputs
      // Do not return value of hidden inputs
      .filter(input => input.type !== 'hidden')
      // Filter out inputs that do not have any value
      .filter(input => optionValues.hasOwnProperty(input.name))
      .map(input => {
        return {
          name: input.label[locale] || 'Unknown',
          value: getInputValueAsText(input, optionValues[input.name], locale),
        };
      })
  );
}

function getInputValueAsText(
  input: ProductInput,
  value: OptionInputValue,
  locale: Locale,
): string | number {
  const NOT_FOUND: Record<Locale, string> = { en: 'Not found', fr: 'Non trouvé' };
  const BORDER: Record<Locale, string> = { en: 'Border', fr: 'Bord' };

  let valueText: string | number;

  function getValueText(input: ProductInput, value: OptionInputValue): string | number {
    let valueText: string | number;

    switch (input.type) {
      case 'hidden':
        valueText = value as string;
        break;
      case 'select':
      case 'radio':
      case 'gallery':
        const option = input.options.find(option => option.value === value);
        valueText = option?.text[locale] || NOT_FOUND[locale];
        break;
      case 'checkbox':
      case 'switch':
        valueText = value
          ? input.checkedLabel[locale] || 'Checked'
          : input.uncheckedLabel[locale] || 'Unchecked';
        break;
      case 'table':
        valueText = `A: ${(value as Record<string, string>).w1} - B: ${
          (value as Record<string, string>).h1
        }`;
        break;
      case 'number':
        valueText = value as number;
        break;
      default:
        return assertNever(input);
    }

    return valueText;
  }

  if (input.repeat && input.repeat === 'borders' && value && typeof value === 'object') {
    valueText = Object.keys(value)
      .map((border, i) => `${BORDER[locale]} ${i + 1}: ${getValueText(input, value[border])}`)
      .join(', ');
  } else {
    valueText = getValueText(input, value);
  }

  return valueText;
}

export function areAllRequiredValuesProvided(
  productOptions: ProductOption[],
  optionsValues: OptionsValues,
) {
  const allRequiredOptionsHaveValues = productOptions.every(productOption => {
    const optionValue = optionsValues[productOption.id];
    return productOption.inputs
      .filter(
        input =>
          input.required && (!input.conditions || isConditionValid(input.conditions, optionValue)),
      )
      .every(input => {
        if (Array.isArray(optionValue)) {
          if (optionValue.length === 0) return true;

          return optionValue.every(optionValue => {
            const inputValue = optionValue[input.name];
            if (inputValue !== null && typeof inputValue === 'object') {
              return Object.values(inputValue).every(
                value => value !== undefined && value !== null,
              );
            }

            return inputValue !== undefined && inputValue !== null;
          });
        }

        const inputValue = optionValue[input.name];
        if (inputValue !== null && typeof inputValue === 'object') {
          return Object.values(inputValue).every(value => value !== undefined && value !== null);
        }

        return inputValue !== undefined && inputValue !== null;
      });
  });
  if (!allRequiredOptionsHaveValues) return false;

  return true;
}

export function migratePropertiesValues(
  id: ProductId,
  propertiesValues: Record<string, InputValue> | undefined | null,
  optionsValues: OptionsValues,
): {
  optionsValues: OptionsValues;
} {
  if (propertiesValues) {
    // MENUETTO
    if (id === 2 && !optionsValues['59']) {
      return { optionsValues: { 59: propertiesValues, ...optionsValues } };
    }
    // ANDANTE
    if (id === 3 && !optionsValues['60']) {
      return { optionsValues: { 60: propertiesValues, ...optionsValues } };
    }
    // SONATINE
    if (id === 5 && !optionsValues['61']) {
      return { optionsValues: { 61: propertiesValues, ...optionsValues } };
    }
    // INTERMEZZO
    if (id === 6 && !optionsValues['62']) {
      return { optionsValues: { 62: propertiesValues, ...optionsValues } };
    }
    // ARPEGGIO
    if (id === 7 && !optionsValues['63']) {
      return { optionsValues: { 63: propertiesValues, ...optionsValues } };
    }
    // UZZO
    if (id === 157 && !optionsValues['64']) {
      return { optionsValues: { 64: propertiesValues, ...optionsValues } };
    }
    // COLUM-2
    if (id === 8 && !optionsValues['65']) {
      return { optionsValues: { 65: propertiesValues, ...optionsValues } };
    }
    // COLUM-3
    if (id === 9 && !optionsValues['66']) {
      return { optionsValues: { 66: propertiesValues, ...optionsValues } };
    }
    // COUP D’EPONGE
    if (id === 10 && !optionsValues['67']) {
      return { optionsValues: { 67: propertiesValues, ...optionsValues } };
    }
    // SUIVANT PLAN CLIENT
    if (id === 28 && !optionsValues['68']) {
      return { optionsValues: { 68: propertiesValues, ...optionsValues } };
    }
    // HOTTISSIMO
    if (id === 26 && !optionsValues['69']) {
      return { optionsValues: { 69: propertiesValues, ...optionsValues } };
    }
    // SERIE ECO
    if (id === 11 && !optionsValues['70']) {
      return { optionsValues: { 70: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN RECTANGULAR
    if (id === 47 && !optionsValues['71']) {
      return { optionsValues: { 71: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN PLATE
    if (id === 12 && !optionsValues['72']) {
      return { optionsValues: { 72: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN ON PLAN
    if (id === 52 && !optionsValues['74']) {
      return { optionsValues: { 74: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN MUSHROOM
    if (id === 50 && !optionsValues['75']) {
      return { optionsValues: { 75: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN HALF MOON
    if (id === 48 && !optionsValues['76']) {
      return { optionsValues: { 76: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN FOLDED EDGES
    if (id === 93 && !optionsValues['73']) {
      return { optionsValues: { 73: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN CUTTED/ROUND ANGLE
    if (id === 49 && !optionsValues['77']) {
      return { optionsValues: { 77: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN ASSEMBLY 2
    if (id === 51 && !optionsValues['78']) {
      return { optionsValues: { 78: propertiesValues, ...optionsValues } };
    }
    // WORKPLAN ASSEMBLY 3
    if (id === 54 && !optionsValues['79']) {
      return { optionsValues: { 79: propertiesValues, ...optionsValues } };
    }
    // ACCESSORY ANGLES
    if (id === 18 && !optionsValues['80']) {
      return { optionsValues: { 80: propertiesValues, ...optionsValues } };
    }
    // ACCESSORY CHIPBOARD
    if (id === 33 && !optionsValues['81']) {
      return { optionsValues: { 81: propertiesValues, ...optionsValues } };
    }
    // ACCESSORY FLAT BORDER CUSTOM
    if (id === 20 && !optionsValues['82']) {
      return { optionsValues: { 82: propertiesValues, ...optionsValues } };
    }
    // ACCESSORY FLAT BORDER TRANSLATE
    if (id === 95 && !optionsValues['83']) {
      return { optionsValues: { 83: propertiesValues, ...optionsValues } };
    }
    // CREDENCE STANDARD
    if (id === 1 && !optionsValues['84']) {
      return {
        optionsValues: { 84: { 'backsplash-standard': propertiesValues }, ...optionsValues },
      };
    }
  }

  return { optionsValues };
}
