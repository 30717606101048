import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NextHead from 'next/head';
import React, { ReactNode } from 'react';

import useUserGroup from 'client/hooks/useUserGroup';

import { useTranslation } from 'client/utils/i18n';
import { getTheme } from 'client/utils/styles';

const MUIProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const group = useUserGroup();

  const theme = React.useMemo(() => {
    return getTheme(group.theme);
  }, [group]);

  const localeText = React.useMemo(
    () => ({ cancelButtonLabel: t('main:cancel'), okButtonLabel: t('main:ok') }),
    [t],
  );

  return (
    <>
      <NextHead>
        <meta name="theme-color" content={group.theme.primaryColor} />
      </NextHead>
      <CssBaseline />
      {/* <StyledEngineProvider injectFirst> */}
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} localeText={localeText}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
      {/* </StyledEngineProvider> */}
    </>
  );
};

export { MUIProvider };
