import { useSession } from 'next-auth/react';

import groups, { Group } from 'data/groups';

const DEFAULT_GROUP_ID = 1;

const defaultGroup = groups.find(({ id }) => id === DEFAULT_GROUP_ID);
if (!defaultGroup) throw new Error('Default group not found');

function getGroup(groupId: number) {
  let group = groups.find(({ id }) => id === groupId);

  if (!group) {
    group = defaultGroup!;
  }

  return group;
}

const useUserGroup = (): Readonly<Group> => {
  const session = useSession();

  return session.data?.user.groupId ? getGroup(session.data?.user.groupId) : defaultGroup;
};

export default useUserGroup;
