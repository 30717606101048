import {
  TFunction,
  Trans,
  appWithTranslation,
  useTranslation as nextUseTranslation,
} from 'next-i18next';
import { useRouter } from 'next/router';

import { Locale } from 'types/translations';

const localeToCountry: Record<Locale, 'FR' | 'GB'> = {
  en: 'GB',
  fr: 'FR',
};

export const useTranslation = () => {
  const resultNextUseTranslation = nextUseTranslation();
  const router = useRouter();

  const locale = (router.locale as Locale) || (router.defaultLocale! as Locale);

  return {
    ...resultNextUseTranslation,
    i18n: {
      ...resultNextUseTranslation.i18n,
      language: locale,
      country: localeToCountry[locale],
    },
  };
};

export { appWithTranslation, Trans };
export type { Locale, TFunction };
