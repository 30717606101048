import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import React from 'react';

export type TextFieldInputProps = Omit<MuiTextFieldProps, 'onChange'> & {
  multiline?: false;
  step?: HTMLInputElement['step'];
  pattern?: HTMLInputElement['pattern'];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
};
export type TextFieldTextAreaProps = Omit<MuiTextFieldProps, 'onChange'> & {
  multiline: true;
  step?: undefined;
  pattern?: HTMLInputElement['pattern'];
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  errorMessage?: string;
};

export type TextFieldProps = TextFieldInputProps | TextFieldTextAreaProps;

function TextField(
  {
    helperText,
    error,
    errorMessage,
    inputRef: inputRefProp,
    pattern,
    required,
    step,
    ...props
  }: TextFieldProps,
  ref: any,
) {
  const [isValid, setIsValid] = React.useState(true);
  const [hasBeenInteractedWith, setHasBeenInteractedWith] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>();
  const handleInputRef = useForkRef(inputRef, inputRefProp || null);

  React.useEffect(() => {
    if (props.value && !hasBeenInteractedWith) {
      setHasBeenInteractedWith(true);
    }

    if (inputRef.current && hasBeenInteractedWith) {
      setIsValid(inputRef.current.checkValidity());
    }
  }, [props.value, hasBeenInteractedWith, required]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsValid(event.currentTarget.checkValidity());
    setHasBeenInteractedWith(true);

    if (props.onChange) {
      props.onChange(event as any);
    }
  }

  function handleInvalid(event: React.InvalidEvent<HTMLInputElement>) {
    setIsValid(false);
    setHasBeenInteractedWith(true);

    if (props.onInvalid) {
      props.onInvalid(event);
    }
  }

  const showAsError = typeof error !== 'undefined' && error !== null ? error : !isValid;

  return (
    <MuiTextField
      {...props}
      ref={ref}
      inputRef={handleInputRef}
      inputProps={{ step, ...props.inputProps, pattern }}
      helperText={
        showAsError ? (
          <>
            {helperText && (
              <>
                {helperText}
                <br />
              </>
            )}

            {errorMessage}
          </>
        ) : (
          helperText
        )
      }
      error={showAsError}
      required={required}
      onChange={handleChange}
      onInvalid={handleInvalid}
    />
  );
}

export default React.forwardRef(TextField);
