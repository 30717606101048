import 'client/utils/polyfills';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { SSRConfig } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';

import BrowserChecker from 'client/components/BrowserChecker';
import GTM from 'client/components/GTM';
import { MUIProvider } from 'client/components/MUI';

import { APIProvider } from 'client/hooks/useAPI';
import { CalculatedCartItemsDetailsProvider } from 'client/hooks/useCalculatedCartItemsDetails';
import { CartProvider } from 'client/hooks/useCart';
import { NotificationsProvider } from 'client/hooks/useNotifications';
import { SignInProvider } from 'client/hooks/useSignIn';

import { appWithTranslation } from 'client/utils/i18n';
import { OptimizelyProvider } from 'client/utils/optimizely';
import { createEmotionCache } from 'client/utils/styles';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps<{ session?: Session } & SSRConfig> {
  emotionCache?: EmotionCache;
}

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
  emotionCache = clientSideEmotionCache,
}: MyAppProps) => {
  return (
    <APIProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="user-scalable=0, initial-scale=1, minimum-scale=1, width=device-width, height=device-height"
          />

          <title>Crédence-inox.com</title>
          <link rel="icon" href="/favicon.ico" />

          <style>{'body { margin: 0; padding: 0; }'}</style>
        </Head>
        <GTM />
        <BrowserChecker>
          <SessionProvider session={session}>
            <OptimizelyProvider>
              <MUIProvider>
                <NotificationsProvider>
                  <CartProvider>
                    <SignInProvider>
                      <CalculatedCartItemsDetailsProvider>
                        <Component {...pageProps} />
                      </CalculatedCartItemsDetailsProvider>
                    </SignInProvider>
                  </CartProvider>
                </NotificationsProvider>
              </MUIProvider>
            </OptimizelyProvider>
          </SessionProvider>
        </BrowserChecker>
      </CacheProvider>
    </APIProvider>
  );
};

export default appWithTranslation<MyAppProps>(MyApp);
