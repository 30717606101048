import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { OptionsObject, SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { ReactElement, useCallback } from 'react';

const NotificationsProvider = ({ children }: { children: ReactElement }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      dense={false}
      hideIconVariant={false}
      preventDuplicate={false}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

type NotifyOptions = Omit<OptionsObject, 'variant'>;
type Notify = (
  message: string,
  variant: VariantType,
  options?: NotifyOptions,
) => string | number | null | undefined;
type CloseNotification = (key: string) => void;
export interface WithNotificationsProps {
  notify: Notify;
  closeNotification: CloseNotification;
}

const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify: Notify = useCallback(
    (message: string, variant: VariantType = 'default', options: NotifyOptions = {}) => {
      const enqueueOptions: OptionsObject = {
        action(key) {
          return (
            <IconButton color="inherit" onClick={() => closeSnackbar(key)} size="large">
              <CloseIcon />
            </IconButton>
          );
        },
        ...options,
        variant,
      };

      return enqueueSnackbar(message, enqueueOptions);
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return {
    notify,
    closeNotification: closeSnackbar,
  };
};

export default useNotifications;

export { NotificationsProvider };
