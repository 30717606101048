import { useTheme } from '@mui/material';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiSlide from '@mui/material/Slide';
import { TransitionProps as MuiTransitionProps } from '@mui/material/transitions';
import classNames from 'clsx';
import React from 'react';

import styles from './DialogModal.module.css';
import IconButton from './IconButton';
import { CloseIcon } from './Icons';

const Transition = React.forwardRef(function Transition(
  props: MuiTransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <MuiSlide direction="up" ref={ref} {...props} />;
});

interface Props extends MuiDialogProps {
  fullHeight?: boolean;
  enableClose?: boolean;
  component: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  header?: React.ReactNode;
}

const DialogModal = ({
  component: Component,
  header,
  children,
  fullWidth,
  fullHeight = false,
  onClose,
  enableClose = true,
  ...props
}: Props) => {
  const theme = useTheme();

  return (
    <MuiDialog
      TransitionComponent={Transition}
      onClose={onClose}
      {...props}
      classes={{
        paper: `${styles.paper} ${fullWidth ? styles.fullWidth : ''} ${
          fullHeight ? styles.fullHeight : ''
        }`,
      }}
    >
      {enableClose && (
        <IconButton
          className={styles.icon}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          onClick={onClose as any}
          size="large"
          color="primary"
        >
          <CloseIcon className={styles.close} />
        </IconButton>
      )}
      <Component className={classNames([styles.container, fullHeight && styles.fullHeight])}>
        {header && <MuiDialogTitle>{header}</MuiDialogTitle>}
        {children}
      </Component>
    </MuiDialog>
  );
};

export default DialogModal;
