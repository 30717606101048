import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import React from 'react';

import useWidth from 'client/hooks/useWidth';

import DialogFullScreen from './DialogFullScreen';
import DialogModal from './DialogModal';

interface Props extends MuiDialogProps {
  fullHeight?: boolean;
  enableClose?: boolean;
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  header?: React.ReactNode;
  disableFullScreen?: boolean;
}

const Dialog = ({
  component = 'div',
  fullScreen,
  fullWidth,
  fullHeight = false,
  enableClose = true,
  disableFullScreen = false,
  ...props
}: Props) => {
  const width = useWidth();
  const displayFullScreen =
    typeof fullScreen === 'boolean'
      ? fullScreen
      : disableFullScreen
      ? false
      : width === 'xs' || width === 'sm';

  if (displayFullScreen) {
    return <DialogFullScreen component={component} enableClose={enableClose} {...props} />;
  } else {
    return (
      <DialogModal
        component={component}
        fullWidth={fullWidth}
        fullHeight={fullHeight}
        enableClose={enableClose}
        {...props}
      />
    );
  }
};

export default Dialog;
