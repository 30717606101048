import { OptimizelyProvider as Provider, createInstance } from '@optimizely/react-sdk';
import { useSession } from 'next-auth/react';
import React from 'react';

const optimizely = createInstance({
  sdkKey: 'EuZGukG5sYcCZExFY2gsr',
});

export const OptimizelyProvider = ({ children }: { children: React.ReactElement }) => {
  const session = useSession();

  const userInfo = React.useMemo(
    () => ({
      id: session.data?.user.idAsNumber.toString() ?? 'unknown',
      attributes: { email: session.data?.user.email ?? 'unknown@example.com' },
    }),
    [session.data?.user.email, session.data?.user.idAsNumber],
  );

  return (
    <Provider optimizely={optimizely} user={userInfo}>
      {children}
    </Provider>
  );
};
